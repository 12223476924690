import { Trans } from '@lingui/macro';
import { Button } from '@we-make-websites/ui-lib/components';
import type { ReactNode } from 'react';
import IconClose from '@/icons/misc/close.svg';
import styles from '../signUpPopup.module.scss';

type SignUpConfirmedProps = {
  signUpConfirmedPopupFormPage: {
    title?: string;
    copy?: ReactNode;
    image?: {
      url: string;
    };
  };
  onCloseClick: () => void;
  handleShopNow: () => void;
};

const SignUpConfirmed = ({
  signUpConfirmedPopupFormPage,
  onCloseClick,
  handleShopNow,
}: SignUpConfirmedProps) => {
  return (
    <div className={styles.signUpConfirmedPopup}>
      <div className={styles.signUpConfirmedPopup__formContainer}>
        <h2>{signUpConfirmedPopupFormPage.title}</h2>
        <p>{signUpConfirmedPopupFormPage.copy}</p>
        <button
          className={styles.signUpConfirmedPopup__closeButton}
          onClick={onCloseClick}
        >
          <IconClose />
        </button>
        <Button
          className={styles.signUpConfirmedPopup__shopNowButton}
          onClick={handleShopNow}
          modifiers={['black', 'mini']}
        >
          <Trans id="sign_up_pop_up_confirmation.shop_now">Browse now</Trans>
        </Button>
      </div>
      <div
        className={styles.signUpConfirmedPopup__imageContainer}
        style={{
          backgroundImage: `url(${signUpConfirmedPopupFormPage.image?.url})`,
        }}
      />
    </div>
  );
};

export default SignUpConfirmed;
